import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/components/navigation.css';

const Navigation = () => {


    return (
        <div>
            <div className="navigation">
                <div className='navigation_ul'>
                    <NavLink to="/arroweb">
                        <p className='navigation_ul_li'>ArroWeb Développement</p>
                    </NavLink>
                    <NavLink to="/realisations">
                        <p className='navigation_ul_li'>Mes réalisations</p>
                    </NavLink>
                    {/* <NavLink to="/projets">
                        <p className='navigation_ul_li'>Votre projet</p>
                    </NavLink> */}
                    <NavLink to="/contact">
                        <p className='navigation_ul_li'>Contact</p>
                    </NavLink>

                </div>
            </div>
        </div>
    );
};

export default Navigation;