import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../../styles/components/formulaire.css';

const Formulaire = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        const contactDetail = process.env.REACT_APP_SERVICE_ID;
        const contactForm = process.env.REACT_APP_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_PUBLIC_KEY;
        console.log(publicKey)
        emailjs.sendForm(contactDetail, contactForm, form.current, publicKey)
            .then((result) => {
                alert('message sent successfully...');
                console.log(result.text);
            },
                (error) => { console.log(error.text); });
    };

    return (
        <div>
            <h1>Formulaire de contact</h1>
            <form className='cf' ref={form} onSubmit={sendEmail}>
                <div className='half left cf'>
                    <input type='text' placeholder='Nom' name='user_name' required />
                    <input type='email' placeholder='Adresse mail' name='user_email' required />
                </div>
                <div className='half right cf'>
                    <textarea name='message' type='text' placeholder='Message' required></textarea>
                </div> <input type='submit' value='Envoyer' id='input-submit' />
            </form>
        </div>

    );
};

export default Formulaire;