import React from 'react';
import Banner from '../components/Banner';
import Navigation from '../components/navigation';
import Footer from '../components/Footer';
import Formulaire from '../components/contact/Formulaire';
import '../styles/pages/contact.css';

const Contact = () => {
    return (
        <div>
            <Banner />
            <Navigation />
            <h2>Vous souhaitez démarrer votre projet</h2>
            <h2>Me demander des renseignements</h2>
            <div className='contact_mail'>
                <h2>Envoyer un mail à</h2>
                <a href="mailto:contact@arrowebdeveloppement.fr"><h2>contact@arrowebdeveloppement.fr</h2></a>
                <h2>ou compléter le formulaire</h2>
            </div>
            <Formulaire />
            <Footer />
        </div>

    );
};

export default Contact;