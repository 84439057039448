import React from 'react';
import Banner from '../components/Banner';
import Navigation from '../components/navigation';
import Footer from '../components/Footer';
import '../styles/pages/arroweb.css';

const Arroweb = () => {
    return (
        <div>
            <Banner />
            <Navigation />
            <h1 className='arroweb_titre'>ArroWeb Développement</h1>
            <p className='arroweb_texte'>ArroWeb Développement est né de la passion de l'informatique et du développement de son créateur Fabrice FLECHE</p>
            <p className='arroweb_texte'>Titulaire d'une formation de niveau 5 en développement web</p>
            <p className='arroweb_texte'>ArroWeb Developpement est à votre écoute de vos besoins pour la réalisation de votre site web</p>

            <Footer />
        </div>

    );
};

export default Arroweb;