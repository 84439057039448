import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/components/footer.css';

const Footer = () => {
    return (
        <div className="footer">
            <div className='footer_ul'>
                <NavLink to="/Mentions">
                    <p className='footer_ul_li'>Mentions Légales</p>
                </NavLink>
                <NavLink to="/Donnee">
                    <p className='footer_ul_li'>Politique de protection des données</p>
                </NavLink>
                <p className='footer_ul_li'>Conditions générales d'utilisation</p>
            </div>
            <div className='footer_cop'>
                <p>Copyright ArroWeb Développement</p>
            </div>
        </div>
    );
};

export default Footer;