//import logo from './logo.svg';
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import Realisation from './pages/Realisation';
import Arroweb from './pages/Arroweb';
import Mentions from './pages/mentions';
import Error from './components/error';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='contact' element={<Contact />} />
        <Route path='realisations' element={<Realisation />} />
        <Route path='arroweb' element={<Arroweb />} />
        <Route path='mentions' element={<Mentions />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
