import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo2.png'
import '../styles/components/banner.css';

const Banner = () => {
    return (
        <div className='arroweb_banner'>
            <NavLink to="/">
                <img className='arroweb_logo' src={logo} alt='ArroWebDeveloppement' />
                <p className='arroweb_logo_nom'>Créateur de site web</p>
            </NavLink>
        </div>
    );
};

export default Banner;