import React from 'react';
import Banner from '../components/Banner';
import Navigation from '../components/navigation';
import Footer from '../components/Footer';
import codeterre from '../assets/codeterre.jpg'
import '../styles/pages/home.css';

const Home = () => {
    return (
        <div>
            <Banner />
            <Navigation />
            <h2 className='creation'>Confiez la création de votre site web à</h2>
            <h2 className='creation'>ArroWeb Développement</h2>
            <p className='home_texte'>C'est l'assurance d atteintre votre objectif :</p>
            <p className='home_texte2'>- de visibilité</p>
            <p className='home_texte2'>- de E-commerce</p>
            <img className='home_img' src={codeterre} alt="code" />
            <Footer />
        </div>
    );
};

export default Home;